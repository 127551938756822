<template>
  <div class="store">
    <div class="title">
      <div @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <div>门店列表</div>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="我也是有底线的~"
      @load="onLoad"
      offset="20"
      :immediate-check="false"
    >
      <ul class="list" v-if="list.lenght != 0">
        <li
          class="list-li"
          v-for="(item, idx) in list"
          :key="idx"
          @click="handleDootData(item)"
        >
          <div class="li-postion">
            <img :src="item.imgUrl" v-if="item.imgUrl" />
            <img src="https://image.lingane-sport.com/img/9525d18d-4e4b-4d6e-8d0c-b9ac2938535a.png" v-else />
          </div>
          <div class="li-item">
            <div class="item-all">
              <h3>{{ item.shopName }}</h3>
              <p>地址：{{ item.address }}</p>
              <div>
                <div class="item-total color-red">{{ item.todayRevenue }}</div>
                <div class="item-text">今日资金收入</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>

<script>
import { Toast, List } from "vant";
export default {
  name: "store",
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      pageSize: 10,
      pageNum: 1,
      total: 0
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.handleFirstData()
    },
    handleFirstData() {
      let _this = this;
      _this.$API
        .getShopList({ pageSize: _this.pageSize, pageNum: _this.pageNum })
        .then(function(res) {
          if (res.code == 200) {
            _this.total = res.total
              //进行判断
            if(_this.total <= _this.pageSize){
              _this.list = res.rows
            }else{
              _this.pageNum++;
              let arr = res.rows;
              _this.list = _this.list.concat(arr);
            };
            // 加载状态结束
            _this.loading = false;
            // 数据全部加载完成
            if (_this.list.length >= _this.total) {
              _this.finished = true;//结束，显示我也是有底线的
            };
          } else {
            Toast(res.msg);
          }
        });
    },
    handleDootData(res) {
      localStorage.shopId = res.shopId;
      localStorage.shopName = res.shopName;
      
      this.$router.push({ name: "Capital", query: { shopId: res.shopId } });
    },
    goBack() {
      this.$router.push({ name: "Home" });
    }
  }
};
</script>
<style lang="scss" scoped>
.store {
  padding: 30px;
  .title {
    display: flex;
    align-items: center;
    i {
      font-size: 30px;
    }
    > div:nth-child(2) {
      flex: 5;
      text-align: center;
      font-size: 38px;
    }
  }
  .list {
    margin: 20px 0;
    .list-li {
      position: relative;
      .li-item {
        width: 470px;
        // height: 2px;
        padding: 24px;
        margin: 31px 0;
        margin-left: auto;
        background: #ffffff;
        box-shadow: 0px 7px 18px 0px rgba(224, 226, 230, 0.2);
        border-radius: 12px;
      }
      .item-all {
        margin-left: 76px;
        > div {
          display: flex;
        }
        h3 {
          font-size: 28px;
          color: #19191a;
          font-weight: bold;
        }
        > p {
          color: #b3b3b3;
          font-size: 22px;
          margin-top: 20px;
          margin-bottom: 28px;
          line-height: 30px;
        }
        .item-total {
          font-size: 26px;
          font-weight: bold;
        }
        .item-text {
          font-size: 11px;
          margin-left: 30px;
          color: #999999;
        }
      }
      .li-postion {
        position: absolute;
        width: 82px;
        height: 82px;
        border-radius: 50%;
        // background: url('~@/assets/img/head-bg.png');
        background-size: 100% 100%;
        border: 8px solid #ffffff;
        left: 10%;
        top: 35%;
        transform: translate(-50%, -50%);
        z-index: 33;
        box-shadow: -5px 1px 12px 0px rgba(75, 171, 254, 0.1);
        border-radius: 50%;
        >img{
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          // margin-left: 4px;
        }
      }
    }
  }
}
</style>
